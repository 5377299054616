import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import { introduction, course } from 'data/data'

import {
  Layout,
  Header,
  SEO,
  Footer,
  Introduction,
  About,
  Course,
  Testimonial,
  Timeline,
  Navbar,
  ContactArea,
  Button,
  Column,
  Row,
  HowToStudy
} from 'components'

const MainPage = () => (
  <Layout>
    <SEO
      title='Otterwise - Tecnologia, ruptura e autonomia'
      description={introduction.text}
      author='Otterwise'
      url='otterwise.co'
      keywords='HTML, CSS, Javascript, React, Curso Online, Otterwise, Realocação Profissional, Programação Web'
    />
    <Helmet title='Home' defer={false} />
    <Navbar />
    <Header
      title='Tecnologia, ruptura e autonomia'
      text='Um espaço voltado ao desenvolvimento da autonomia e crescimento profissional, conduzido por uma aprendizagem focada na tecnologia com a finalidade da transformação digital.'
    />
    <Introduction />
    <About />
    {/* <Course
      title={course.title || ''}
      subtitle={course.subtitle || ''}
      text={{ text_1: course.text_1 || '', text_2: course.text_2 || '' }}
      schedule={course.schedule || []}
    /> */}
    <Timeline />
    <HowToStudy />
    <Testimonial />
    <ContactArea />
    <Footer />
  </Layout>
)

export default MainPage
